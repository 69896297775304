.addendum {
  display: flex;
  align-items: center;
  gap: var(--default_increment);
  cursor: pointer;
  padding: unset;
  background-color: transparent;
  color: var(--ion-color-primary-darker);
  composes: bodySBold from global;
}
