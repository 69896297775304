.pageHeader {
  width: 100%;
  padding-bottom: calc(3 * var(--default_increment));
}

.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--default_increment);
}

.moment {
  display: flex;
}

.facilityTitle {
  margin-bottom: var(--default-increment);
}

.facilityInfo {
  display: flex;
}

.facilityInfoTitle {
  color: var(--ion-color-primary);
}

.tileBanner {
  z-index: 100;
  font-size: 0.81rem;
  font-weight: bold;
  position: absolute;
  left: 7px;
  top: 5px;
  color: var(--ion-color-primary-darker);
  margin: 0;
}

.facilityDetailsText {
  display: none;
  vertical-align: middle;
  text-decoration: underline;
}

.dateIcon {
  padding-right: 0.28rem;
}

.menuButtonContainer {
  margin-left: var(--column-spacing);
}

.price {
  color: var(--ion-color-primary-darker);
  font-weight: 700;
  padding-top: var(--default_increment);
}

@media screen and (min-width: 960px) {
  .facilityDetailsText {
    display: block;
  }

  .facilityDetailsIcon {
    margin-right: 0.1875rem;
  }

  .dateIcon {
    vertical-align: middle;
    padding-right: 0.28rem;
  }
}
